import { useState, useEffect,useContext } from 'react';
import Image from 'next/image';
import Link from "next/link";
import { CartContext } from "../../contexts/CartContext";
import { setImageSrc } from '../../utils/config';

/**
 * Snackbar component for displaying notifications related to cart actions.
 * 
 * This component shows a snackbar notification when a product is added to the cart.
 * It displays a message and provides links to view the cart or proceed to checkout.
 * 
 * @param {Object} product - The product related to the notification.
 * @param {boolean} isOpen - Flag to control the visibility of the snackbar.
 * @param {Function} setIsOpen - Function to update the isOpen state.
 */
export const Snackbar = ({ product, isOpen, setIsOpen,quantity="",relatedProducts=""  }) => {

  

  const [exit, setExit] = useState(false); // State for exit animation
  const { cart, addToCart, updateQuantity, isSnackbarVisible, cartResponse } = useContext(CartContext);

    // Determine the image source
    const imageSrc = setImageSrc(
      product?.json?.Images || 
      product?.image || 
      product?.images?.base_image || 
      product?.image_url,
      product?.name || product?.product_name
    );
  
    // Validate the image source
    const isImageValid = imageSrc && typeof imageSrc === 'string' && imageSrc.trim() !== '';

  // Effect to handle auto-closing of the snackbar
  useEffect(() => {
    if (isOpen) {
      // Set a timeout to trigger the exit animation
      setTimeout(() => setExit(true), 5000);

      // Set a timeout to close the snackbar after the animation
      setTimeout(() => {
        setIsOpen(false);
        setExit(false);
      }, 5000);
    }
  }, [isOpen, setIsOpen]);

  // Return null if the snackbar shouldn't be open
  if (!isOpen) {
    return null;
  }
let message;
  if ((quantity != "" && quantity > 0)) {
    message = `Cart updated: ${quantity} ${quantity == 1 ? "item" : "items"} in  cart.`;
  } else if ((quantity != "" && quantity == 0)) {
    message = `has been removed from your cart.`;
  } else if (cartResponse == "Already Same Data Exists") {
    message = `is already in the cart.`;
  } else {
    message = `has been added to the cart.`; // Default message
  }

  const containerClass = relatedProducts == "true"
  ? `fixed z-50 left-5 bottom-5 w-full max-w-md bg-white p-4 rounded shadow-lg flex justify-between items-center transition-transform duration-200 transform-gpu ${
      exit ? "translate-y-full" : "translate-y-0"
    }`
  : `fixed z-50 left-0 lg:left-20 bottom-20 w-96 bg-white p-4 rounded shadow-lg flex justify-between transition-transform duration-200 transform-gpu ${
      exit ? "translate-y-full" : "translate-y-0"
    }`;

  // JSX for the Snackbar component
  return (
    <>
<div className={`${containerClass} max-w-full`}>
  <div className="flex items-center">
  {imageSrc && (
          <div className="w-20 h-20 relative">
            <img
              src={imageSrc}
              alt={product?.name || product?.product_name || product?.json["Product Name"]}
              className="rounded-lg"
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            />
          </div>
        )}

    <div className="ml-4 flex-grow">
      {/* Ensure the title is truncated properly */}
      <h3
        className="text-xl font-bold mb-2 truncate max-w-[200px] overflow-hidden"
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        title={product?.name || product?.product_name || product?.json["Product Name"]}  // Show full text on hover
      >
        {product?.name || product?.product_name || product?.json["Product Name"]}
      </h3>

      <p className="text-sm">{message}</p>

      {quantity != 0 || !quantity ? (
  <div className="mt-3 flex gap-2">
    <Link
      href="/Cart"
      className="bg-primary text-white text-sm rounded px-4 py-2 flex items-center justify-center"
    >
      <i className="fas fa-shopping-cart"></i> {/* Font Awesome cart icon */}
    </Link>
    <a
      href="/checkout/Checkout"
      className="bg-primary text-white text-sm rounded px-4 py-2 flex items-center justify-center"
    >
      <i className="fas fa-credit-card"></i> {/* Font Awesome credit card icon */}
    </a>
  </div>
) : null}

    </div>
  </div>
</div>


    </>
  

  );
};
